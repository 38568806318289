import { useNavigate, useParams } from "react-router-dom";
import { useSettingsContext } from "../components/common/settings/SettingsContext";
import { useGetSongQuery } from "../services/songs";
import Box from "@mui/material/Box";
import { Container, Divider, IconButton, MenuItem, Slider, Stack, Switch, Typography } from "@mui/material";
import { IChord, ISong } from "../types/Song";
import { useEffect, useState } from "react";
import Iconify from "../components/common/iconify/Iconify";
import MenuPopover from "../components/common/menu-popover";

export const CHORD_CHAIN = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "Bb", "H"];

export default function ViewSongPage() {
  const { themeStretch, themeMode } = useSettingsContext();

  const { id = "" } = useParams();
  const navigate = useNavigate();

  const [songList,] = useState<ISong[]>(JSON.parse(localStorage.getItem('songList') as string) || [])
  const [lyrics,] = useState(songList.find((song: ISong) => song._id === id))
  const { data: song } = useGetSongQuery(id);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const [chordPosition, setChordPosition] = useState(0)
  const [displayChords, setDisplayCords] = useState<boolean>(localStorage.getItem("displayChords") === 'true' ? true : false)

  const [dinamicFontSize, setDinamicFontSize] = useState(Number(localStorage.getItem('fontSize')) || 16)
  const [dinamicFontFamily,] = useState(localStorage.getItem('font') || 'Public Sans,sans-serif')

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0)
  }, [])

  const convertChordsToString = (chords: IChord[]) => {
    return chords.reduce((acc: string, chord: IChord) => {
      const length = CHORD_CHAIN.length;
      const pos = ((chord.position + chordPosition) % length + length) % length;
      return `${acc}${' '.repeat(chord.spaces)}${CHORD_CHAIN[pos]}${chord.postfix || ''}`
    }, '');
  }

  const itemNameTranslate = (name: string) => {
    return name === 'couplet' ? 'куплет:' : name === 'chorus' ? 'приперв:' : 'мост:'
  }

  const onChangeDinamicFontSize = (value: any) => {
    setDinamicFontSize(value)
    localStorage.setItem('fontSize', value)
  }

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const changeTon = (value: number) => {
    setChordPosition(chordPosition + value)
  }

  const onChangeDisplayChords = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayCords(event.target.checked)
    localStorage.setItem("displayChords", JSON.stringify(event.target.checked))
  }

  const backToSongList = () => {
    navigate('/app/songs/list');
  }

  const findMistake = () => {
    navigate('/app/help');
  }

  return (
    <>
      {
        lyrics &&
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton onClick={backToSongList}><Iconify icon="ion:arrow-back-sharp" sx={{ color: "gray" }} width={22} /></IconButton>
              <Typography color="gray" fontSize={18}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  maxWidth: '260px',
                  textOverflow: 'ellipsis'
                }}
              >{lyrics.songNumber}. {lyrics.name}</Typography>
            </Box>

            <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}><Iconify icon="ep:setting" sx={{ color: "gray" }} width={22} /></IconButton>
          </Box>
          <Box
            my={1}
          >
            {
              lyrics.items.map((item, index) => <Box key={index}>
                <Typography fontSize={dinamicFontSize / 1.14} paddingLeft={2} sx={{ background: themeMode === 'dark' ? "#d3d3d33d" : '#d3d3d330' }}>{itemNameTranslate(item.name)}</Typography>
                {
                  item.lines.map((line: any, idx: number) =>
                    <Box key={idx}>
                      <Stack>
                        {displayChords &&
                          <Typography
                            fontSize={dinamicFontSize + 1}
                            sx={{
                              whiteSpace: 'break-spaces',
                              fontFamily: 'Mulish-BoldItalic',
                              fontWeight: 'bolder',
                              color: themeMode === 'dark' ? '#03a9f4' : '#454F5B',
                            }}
                          >{convertChordsToString(line.chords)}</Typography>
                        }
                        <Typography fontWeight={600} fontSize={dinamicFontSize} fontFamily={dinamicFontFamily}>{line.text}</Typography>
                      </Stack>
                    </Box>)
                }
                <br />
              </Box>)
            }
          </Box>
        </Container>
      }

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 310 }}
      >
        <MenuItem
          onClick={() => {
            // handleClosePopover();
          }}
        // sx={{ color: 'error.main' }}
        >
          <Iconify icon="bx:font-size" width={32} fontSize={22} />
          <Slider
            size="small"
            aria-label="font-slider"
            valueLabelDisplay="auto"
            value={dinamicFontSize}
            onChange={(v: any) => onChangeDinamicFontSize(v.target.value)}
            min={10}
            max={30}
            step={.5}
            sx={{
              maxWidth: '300px',
              color: 'rgb(27, 87, 27)',
              height: 8,
              '& .MuiSlider-track': {
                border: 'none',
              },
              '& .MuiSlider-thumb': {
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                  boxShadow: 'inherit',
                },
                '&:before': {
                  display: 'none',
                },
              },
              '& .MuiSlider-valueLabel': {
                lineHeight: 1.2,
                fontSize: 12,
                background: 'unset',
                padding: 0,
                width: 32,
                height: 32,
                borderRadius: '50% 50% 50% 0',
                backgroundColor: '#52af77',
                transformOrigin: 'bottom left',
                transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                '&:before': { display: 'none' },
                '&.MuiSlider-valueLabelOpen': {
                  transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                },
                '& > *': {
                  transform: 'rotate(45deg)',
                },
              },
            }}
          />
        </MenuItem>

        <MenuItem
          onClick={() => {
            // handleClosePopover();
          }}
          sx={{ color: 'black' }}
        >
          {/* <Iconify icon="arcticons:chordanalyser" /> */}
          <Switch
            color="success"
            checked={displayChords}
            onChange={onChangeDisplayChords}
          />
          <IconButton onClick={() => changeTon(-1)} sx={{ color: themeMode === "dark" ? 'white' : 'black' }}><Iconify icon="ic:outline-arrow-back-ios" /></IconButton>
          <Typography sx={{ mr: '16px', color: themeMode === "dark" ? 'white' : 'black' }} fontWeight={600}>{CHORD_CHAIN[song?.items[0].lines[0].chords[0].position + chordPosition]}{song?.items[0].lines[0].chords[0]?.postfix}</Typography>
          <IconButton onClick={() => changeTon(1)} sx={{ color: themeMode === "dark" ? 'white' : 'black' }}><Iconify icon="ic:outline-arrow-forward-ios" /></IconButton>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={findMistake}
        >
          <Typography sx={{ mr: '16px', color: themeMode === "dark" ? 'white' : 'black' }} fontWeight={400}>Нашли ошибку?</Typography>
        </MenuItem>
      </MenuPopover>

    </>
  )
}
